import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

interface Props {
    data: {
        markdownRemark: {
            frontmatter: {
                title: string;
                date: string;
            }
            html: string;
        }
    }
}

const BlogTemplate = ({ data }: Props) => {
    console.log('data', data);
    const { frontmatter, html } = data.markdownRemark;
    return (
        <Layout>
            <SEO />
            <div className={'blog-post-container'}>
                <div className={'blog-post'}>
                    <h1>{frontmatter.title}</h1>
                    <h2>{frontmatter.date}</h2>
                    <div
                        className={'blog-post-content'}
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default BlogTemplate;

export const blogQuery = graphql`
query($path: String!) {
  markdownRemark(frontmatter: { path: { eq: $path } }) {
    html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`